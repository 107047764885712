import React from "react"
import Layout from "../shared/layout/layout"
import "../styles/home.scss"
import SEO from "../shared/seo/seo"
import { graphql, useStaticQuery } from "gatsby"

const Index = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          home {
            title
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title={data.site.siteMetadata.home.title} />
      <div className="position-relative">
        <div className="copertina"></div>
        <span className="desc-img ">
          Un Laboratorio contemporaneo nel cuore del chianti
        </span>
      </div>
    </Layout>
  )
}

export default Index
